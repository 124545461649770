<template>
  <div :class="['mb-4', extraClasses]">
    <div class="results-products__item-new"
      :class="{ 'results-products__item-new_grey': $store.getters.CART_CHECK_PRODUCT(product.id) }"
    >
      <div class="row p-3">
        <template v-if="product.risk && product.risk > 0">
          <div class="col-12 d-flex align-items-center results-products__item-risks"
               v-if="!['X', 'W', 'V', 'U'].includes(product.letter)">
              <span class="results-products__item-risk">
                  Gevonden risico's
              </span>
            <span v-for="index in product.risk"
                  :key="index"
                  class="results-products__item-risk-indicator-v2_small"></span>
          </div>
        </template>
        <div class="col-12">
          <div class="results-products__item-title_m-0">{{ product.title }}</div>
        </div>
        <div class="col-12 mb-3">
          <div>{{ product.type }}</div>
        </div>
        <div class="col-12
          d-flex justify-content-between align-items-center results-products__item-wrapper">
          <div class="results-products__item-price-box">{{ cost }}</div>
          <div v-if="product.moreInfo.length > 0" class="results-products__item-modal-wrapper">
            <div v-if="product.moreInfo.length === 1" class="results-products__item-modal">
              <ButtonModal :title="`Meer informatie`">
                <template v-slot:trigger>
                  <a href="#" class="results-products__item-link" @click.prevent>
                    Meer info
                  </a>
                </template>
                <MoreInfoComponent :product-info="product.moreInfo[0]" />
              </ButtonModal>
            </div>
            <div v-else class="results-products__item-modal">
              <ButtonModal :title="`Waar wil je meer<br />informatie over?`">
                <template v-slot:trigger>
                  <a href="#" class="results-products__item-link" @click.prevent>
                    Meer info
                  </a>
                </template>
                <ZizAccordion inline-template :items="product.moreInfo">
                  <ul class="accordion">
                    <li class="accordion__row"
                        v-for="(item, index) in this.$attrs.items" :key="index">
                      <ListItem extraClasses="first-lvl" :label="item.descriptionTitle">
                        <MoreInfoComponent :product-info="item" />
                      </ListItem>
                    </li>
                  </ul>
                </ZizAccordion>
              </ButtonModal>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="results-products__item-the-info"
          :class="{
            'results-products__item-the-info_grey': $store.getters.CART_CHECK_PRODUCT(product.id)
          }">
            <div v-if="productDisabledMessage(product, $store).length"
                 v-html="productDisabledMessage(product, $store)"
            />
            <a v-else-if="!$store.getters.CART_CHECK_PRODUCT(product.id)"
               href="#"
               class="results-products__item-add_to_cart_new"
               @click.prevent="addToCart">
              Test toevoegen
            </a>
            <a v-else
               href="#"
               class="results-products__item-add_to_cart_new added_to_cart"
               :class="{ 'disabled': productIsMandatory(product, $store) }"
               @click.prevent="removeFromCart">
              {{ addToButtonText }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ButtonModal from './common/ButtonModal';
import MoreInfoComponent from './MoreInfoComponent';
import productMixin from '@/mixins/productMixin';
import ListItem from './widgets/Accordion/item';
import ZizAccordion from './widgets/Accordion/index';

export default {
  name: 'ResultProductComponentV2',
  mixins: [productMixin],
  components:
  {
    ZizAccordion,
    ListItem,
    ButtonModal,
    MoreInfoComponent,
  },
  props: {
    extraClasses: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['CARE', 'CART_CHECK_PRODUCT_BY_LETTER']),
    cost() {
      const cost = this.CARE ? this.product.careCost : this.product.cost;
      return `€${cost},-`;
    },
    addToButtonText() {
      return this.type === 'required' ? 'Verwijder test' : 'Verwijderen';
    },
    populatedProducts() {
      return this.populateProducts([this.product]);
    },
  },
  methods: {
    productDisabledMessage(product, store) {
      if (['W', 'X', 'V'].includes(product.letter)) {
        if (!store.getters.CART_CHECK_PRODUCT_BY_LETTER('L')) {
          return 'HIV-test verplicht';
        }
      }
      if (!product.available) {
        return 'Binnenkort beschikbaar';
      }
      return '';
    },
    productIsMandatory(product, store) {
      if (['O', 'U'].includes(product.letter)) {
        if (store.getters.CART_CHECK_PRODUCT_BY_LETTER('V')) {
          return true;
        }
      }
      return false;
    },
    addToCart() {
      this.$store.commit('ADD_TO_CART', this.product);
    },
    removeFromCart() {
      this.$store.commit('REMOVE_FROM_CART', this.product);
    },
  },
};
</script>

<style scoped>
.results-products__item-risk {
  margin-right: .5rem;
}
.disabled {
  color: #808080FF;
  pointer-events: none;
}
</style>
